import React from 'react'

import "../style/hero.css";

import sun from "../img/markos-sun-set.png";
import linkedinTeal from "../img/linkedin-teal.svg"

function HomeHero() {
  return (
    <div className="bg-dotted">
      <div className="container hero-contact">
        <h1>
          <i>marko rodic</i>
        </h1>
        <a
          href="https://www.linkedin.com/in/xdmr/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn Profile, Marko Rodic, XDMR"
          className='letsTalkWrapper'
        >
          <img className='heroLinkedInTeal' src={linkedinTeal} alt="" />
          <div className="lets-talk text-white">
            <i>let's talk ➔</i>
          </div>
        </a>
      </div>

      <div className="container">
        <div className="h1">
          Designing for <span className="delightful">Delightful</span> <br />{" "}
          User&nbsp;Experiences
        </div>
      </div>
      <div className="hero">
        <div className="container sunset-cover">
          <img src={sun} alt="" className="img-resp sunset" />
        </div>
      </div>
    </div>
  )
}

export default HomeHero