import logos from "./img/branding-logos.svg";
import { useState } from "react";

import "./style/base.css";

import HomeHero from "./components/HomeHero";
import About from "./components/About";
import Project from "./components/Project";
import SmallProject from "./components/SmallProject";
import Footer from "./components/Footer";

import mailhydra from "./img/projects/mailhydra-preview.png";
import mozak from "./img/projects/mozak-preview.png";
import stellar from "./img/projects/stellarlanding-preview.png";
import promiger from "./img/projects/promiger-preview.png";
import uberrenting from "./img/projects/uberrenting-preview.png";
import uberrenting1 from "./img/projects/uber-renting-1.png";
import uberrenting2 from "./img/projects/uber-renting-2.png";
import uberrenting3 from "./img/projects/uber-renting-3.png";
import switchTV from './img/projects/switchTV2-small.38ca63270f3b1f0ff25d.gif'

import converter from "./img/projects/converter-preview.png";

function App() {
  const SmallProjects = [
    {
      title: "Email <strong>converter</strong>",
      description:'<p>This react app converts Markdown Text to Inline Styled, email ready, HTML. I\'ve built this email tool to automize part of the email coding process. Check <a aria-label="Email converter" href=" https://github.com/xdMR/email_converter" target="_blank" rel="noopener noreferrer">GitHub Repo here</a>.</p>',
      image: converter,
      link: "https://www.uie.com/converter",
      linkText: "UIE.com/convrter →"
    },
    {
      title: "Free <strong>Email Template</strong> Library",
      description:
        "<p>I have built a set of beautiful HTML emails that are using the most reliable method for achieving responsive design, fluid tables. MailHydra projects contain 4 email templates that are free to use. I have acquired email coding skills while working as an intern at CenterCentre-UIE.</p>",
      image: mailhydra,
      link: "https://www.mailhydra.com",
      linkText: "MailHydra →",
    },

    {
      title: "SwitchTV",
      description:
        '<p> I was the lead designer for the British telecom startup <a href="https://web.archive.org/web/20210413193010/https://switchtv.co.uk/" target="_blank" aria-label="SwitchTV Website" rel="noopener noreferrer">SwitchTV</a>. My team built the app and I was entrusted with creating the WordPress theme and user interface for the channel sorting tool (<a href="https://xdmr.us/static/media/switchTV1.89ae9d79a51f918dbf14.gif" target="_blank" aria-label="SwitchTV Website" rel="noopener noreferrer">1</a>, <a href="https://xdmr.us/static/media/switchTV2.9c4e751f56bd652c54ec.gif" target="_blank" aria-label="SwitchTV Website" rel="noopener noreferrer">2</a>).</p>',
      image: switchTV,
      link: "https://web.archive.org/web/20210413193010/https://switchtv.co.uk/",
      linkText: "SwitchTV →",
    },

    {
      title: "Web design &amp; internet marketing blog",
      description:
        "<p>I write about web design and internet marketing on mozak.org.</p>",
      image: mozak,
      link: "https://mozak.org/",
      linkText: "Mozak.org →",
    },

    {
      title: "Building <strong>Landing Pages</strong>",
      description:
        '<p>StellarLanding delivers manually crafted, visually appealing, and—most importantly—high-converting landing pages. Read my free guide on creating perfect landing pages on <a aria-label="Stellar Landing Website" href="https://www.stellarlanding.com/perfect-landing-page/" target="_blank" rel="noopener noreferrer">Making Perfect Landing Pages</a>.</p>',
      image: stellar,
      link: "https://www.stellarlanding.com/",
      linkText: "StellarLanding →",
    },

    {
      title: "<strong>Guerrilla</strong> Marketing",
      description:
        "<p>I'm passionate about Guerrilla Marketing, as I believe this is the way David can defeat Goliath in the world of Internet Giants. I'm developing the promiger.com brand dedicated to exploring the possibilities of Guerrilla Marketing in the digital world.</p>",
      image: promiger,
      link: "https://www.promiger.com/",
      linkText: "🏴‍☠️ Promiger →",
    },

    {
      title: "Designing Complex Responsive User Interfaces",
      description: `<p>Building complex interfaces is my current area of interest. Please check out my latest designs for <strong>UberRenting</strong>.(<a aria-label="Screenshot of the Uber Renting Interface" target="_blank" href=${uberrenting1}>1</a>, <a aria-label="Screenshot of the Uber Renting Interface" target="_blank" href=${uberrenting2}>2</a>, <a aria-label="Screenshot of the Uber Renting Interface" target="_blank" href=${uberrenting3}>3</a>)</p>`,
      image: uberrenting,
      link: uberrenting,
      linkText: "UberRenting →",
    },

    {
      title:
        '<h2 style="background-color: rgb(230, 240, 234); display: inline-block; padding:2px 10px;">List all |</h2>',
      description:
        '<p>mailhydra.com, switchtv.co.uk, hoomit.com, isuzu.ba, metago.info, stallarlanding.com, promiger.com,spelltell.com, mozak.org, codingllama.com, colibri.click, gcev.uie.com, lexnia.com,bonjour-beauty.hr, metrics.centercentre, goals.centercentre.com, agile.centercentre.com, research.centercentre.com, community.centercentre.com, articles.uie.com, winstakeholders.com, uxstrategyprogram.com</p> <p><a aria-label="Portfolio: Marko Rodic" href="https://xdmr.us/marko-portfolio.pdf" target="_blank" rel="noopener noreferrer">Download my Graphic Design Portfolio</a></p> <p>or check it here in an online <a aria-label="Portfolio: Marko Rodic" href="https://gallery.xdmr.us" target="_blank" rel="noopener noreferrer"> 🖼️🖌️ gallery.</a> </p>',
      image: "",
      link: "https://xdmr.us",
      linkText: "xdmr.us →"
    },
  ];
  return (
    <>

      <HomeHero />

      <div className=" " style={{marginTop:"200px"}}>
        <div className="container bg-white">
          <h2>Design
          </h2>
        </div>
      </div>


      <About  />

      <div className="dotted-headline">
        <div className="container mb-30 bg-white">
          <h2 className="pt-10 pb-10">:: Projects</h2>
        </div>
      </div>

      <Project />

      <div className="bg-dotted dotted-headline">
        <div className="container pb-30 pt-30 bg-white">
          <h2>Other projects</h2>
        </div>
      </div>

      {SmallProjects.map((elm) => (
        <SmallProject
          title={elm.title}
          description={elm.description}
          image={elm.image}
          link={elm.link}
          linkText={elm.linkText}
        />
      ))}

      <div className="bg-dotted dotted-headline">
        <div className="container pb-30 pt-30 bg-white">
          <h2>Branding and Marketing</h2>
          <p style={{paddingLeft:"20px"}}>
            Building brands is my passion. As an ex-freelancer, I've poured a
            lot of time and effort into helping small businesses establish
            strong brands. Here are some of the logos I created during that
            process.
          </p>
        </div>
      </div>

      <div class="container-wide">
        <img src={logos} alt="" />
      </div>

      <Footer />
    </>
  );
}

export default App;
