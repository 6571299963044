import React from 'react'
import '../style/footer.css'

import promiger from "../img/promiger-icon.svg";
import linkedin from "../img/linkedin-icon.svg";
import stellar from "../img/stellar-icon.svg";
import github from "../img/github-icon.svg";

function Footer() {
    return (
        <footer className="dark-bg">
            <div className="container">
                <h2 className="text-white pt-50 ">Want to build something
                    amazing?</h2>
                    <a
          href="https://www.linkedin.com/in/xdmr/"
          aria-label="LinkedIn profile, Marko Rodic, XDMR"
          target="_blank"
          rel="noopener noreferrer" className="letsTalk"
        >
          <div>
            <i>let's talk ➔</i>
          </div>
        </a>


                <ul>
                    <li>
                        <a className="text-white" href="mailto:website3@xdmr.us"  aria-label="email address"> info[ᶏt]xdmr.us</a>
                    </li>
                    <li>
                        <a className="text-white"  aria-label="Github Profile - Marko Rodic" href="https://github.com/xdMR">github.com/xdMR</a>
                    </li>
                    <li>
                        <a className="text-white"  aria-label="Portfolio - Marko Rodic" href="https://www.promiger.com/portfolio.pdf">xdmr.us/portfolio.pdf</a>
                    </li>
                </ul>
            </div>
            <div className="container pt-30 pb-20 footerLogos">
                <a href="https://github.com/xdMR/"  aria-label="Github Profile - Marko Rodic" target="_blank" rel="noopener noreferrer"><img src={github} alt="" className="icon" /></a>
                <a href="https://www.linkedin.com/in/xdmr/"  aria-label="LinkedIn profile - Marko Rodic" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="" className="icon"/></a>
                <a href="https://www.stellarlanding.com/" target="_blank" rel="noopener noreferrer"  aria-label="StellarLanding Website"> <img src={stellar} alt="" className="icon"/></a>
                <a href="https://www.promiger.com/" target="_blank"  aria-label="Promiger - Website" rel="noopener noreferrer"> <img src={promiger} alt="" className="icon" /></a>
            </div>
        </footer>
    )
}

export default Footer