import React from 'react'

function SmallProject({ title, description, image, link, linkText }) {
  return (
    <div className="container section-space smallProject">
        <div className="flex">
            <div className="basis40 horiz-center pr-desktop pt-20">
                <img src={image} alt="" className="img-resp small-project-shadow"/>
            </div>
            <div className="basis60">
                <h2 dangerouslySetInnerHTML={{__html:title}}></h2>
                <h3><a href={link} target="_blank"  aria-label={linkText}>{linkText}</a></h3>
                <div dangerouslySetInnerHTML={{__html:description}}></div>
            </div>
        </div>
    </div>
  )
}

export default SmallProject