import React from 'react'

import jared from "../img/jspool-icon.jpg";
import leslie from "../img/leslie-icon.png";
import summer from "../img/summer-icon.png";

import ccCSS from "../img/centercentreProjects/cc-css-development.png";
import ccremail from "../img/centercentreProjects/ccremail.png";
import ccremailBig from "../img/centercentreProjects/ccremailBig.png";
import uxStrategyProgram from "../img/centercentreProjects/uxstrategyprogram.png";
import win from "../img/centercentreProjects/winstakeholders.png";
import aux from "../img/aux-preview.png";
import gcev from "../img/gcev-preview.png";

import communityBig from "../img/centercentreProjects/communityBig.jpeg";
import agileBig from "../img/centercentreProjects/agileBig.png";
//import metricsBig from "../img/centercentreProjects/metricsBig.png";
//import hiringBig from "../img/centercentreProjects/hiringBig.png";
import researchBig from "../img/centercentreProjects/researchBig.png";
import goalsBig from "../img/centercentreProjects/goalsBig.png";

import communitySmall from "../img/centercentreProjects/communitySmall.png";
import agileSmall from "../img/centercentreProjects/agileSmall.png";
import metricsSmall from "../img/centercentreProjects/metricsSmall.png";
import metricsFigma from "../img/centercentreProjects/metricsFigma.png";
import hiringSmall from "../img/centercentreProjects/hiringSmall.png";
import researchSmall from "../img/centercentreProjects/researchSmall.png";
import goalsSmall from "../img/centercentreProjects/goalsSmall.png";

import gitOOPS from "../img/centercentreProjects/githubOOPS.png";
import figmaOOPS from "../img/centercentreProjects/oopsFigma.png";
import liveOOPS from "../img/centercentreProjects/liveOOPS.png";


import strategyProgram from "../img/projects/strategy-program.gif";




function About() {
    return (
        <div className="container" style={{ marginBottom: "200px" }}>
            <div className="w800">
                <p>Hi, I'm Marko, I'm a Web Designer/FrontEnd Developer. I am deeply enthusiastic about the intersection of design, coding, and marketing. My niche is creating <a href="https://stellarlanding.com/perfect-landing-page/" target="_blank" rel="noopener noreferrer">high-converting landing pages</a>. If I'm not designing, I'm probably coding! 😉   </p>

                <h2>UX WORK </h2>
                <p> I had the privilege of working very closely with Mr. Jared Spool for over two years. Mr. Spool is known for the extensive contributions he has made to the UX field. Together with my team of web dev fellows, and    <span className="tw-icon">
                        <img alt="Jared Spool Twitter Icon" src={jared} />
                    </span>
                    <strong>Jared Spool</strong> (
                    <a
                        href="https://twitter.com/jmspool"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Jared Spool Twitter Profile"
                    >
                        @jmspool
                    </a>
                    ),
                    <span className="tw-icon">
                        <img src={leslie} alt="Leslie Jensen-Inman Twiter Icon" />
                    </span>{" "}
                    <strong>Leslie Jensen-Inman</strong> (
                    <a
                        href="https://twitter.com/jenseninman"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Leslie Jensen-Inman Twitter Profile"
                    >
                        @jenseninman
                    </a>
                    ), and
                    <span className="tw-icon">
                        <img src={summer} alt="Leslie Jensen-Inman Twiter Icon" />
                    </span>{" "}
                    <strong>Summer Kohlhorst</strong> (
                    <a
                        href="https://twitter.com/SummerKohlhorst/"
                        aria-label="Summer Kohlhorst Twitter Profile"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        @SummerKohlhorst
                    </a>
                    ), I have helped launching and
                     advertising multiple UX products desinged for senior UX teams from
                    companies like Adobe, LinkedIn and NASA JPL. My web dev team organized
                    and supported multiple UX workshops, UX webinars, and UX consulting
                    sessions.{" "}
                </p>
            </div>



            <div className="w800" >
                <div className="flex" style={{ maxWidth: "700px", margin: "0 auto" }}>
                    <div className="basis30">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href="https://css-cc.pages.dev/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Screenshot of CenterCentreCSS Project"
                                >
                                    <img
                                        src={ccCSS}
                                        alt="CenterCentre CSS Framework"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="basis30">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href="https://winstakeholders.pages.dev/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Screenshot of the landing page for Win Stakeholders and Inflience Decisions"
                                >
                                    <img
                                        src={win}
                                        alt="Win Stakeholders and Inflience Decisions"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="basis30">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href="https://ux-strategy-program.pages.dev"
                                    target="_blank"
                                    aria-label="Screenshot of the landing page for UX Strategy Program"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={uxStrategyProgram}
                                        alt="UX Strategy Program"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex" style={{ maxWidth: "700px", margin: "0 auto" }}>
                    <div className="basis30">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href={ccremailBig}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Screenshot of the landing page for Goals Program"
                                >
                                    <img
                                        src={ccremail}
                                        alt="Game-Changing Experience Vision Landing Page"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="basis30">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href={communityBig}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Screenshot of the landing page for Agile Program"
                                >
                                    <img
                                        src={communitySmall}
                                        alt="Taking Control of Agile UX Landing Page"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="basis30">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href="/strategy-program.mp4"
                                    target="_blank"
                                    aria-label="Screenshot of the landing page for Metrics Program"
                                    rel="noopener noreferrer"
                                >
                                    <img style={{padding:"20px 0px",minHeight:"160px"}}
                                        src={strategyProgram}
                                        alt="Persuasive UX Metrics Landing Page"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="flex" style={{ maxWidth: "700px", margin: "0 auto" }}>

                    <div className="basis25">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href={agileBig}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Screenshot of the landing page for Agile Program"
                                >
                                    <img
                                        src={agileSmall}
                                        alt="Taking Control of Agile UX Landing Page"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="basis25">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href={researchBig}
                                    target="_blank"
                                    aria-label="Screenshot of the landing page for Research Program"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={researchSmall}
                                        alt="Advances Approach To UX Research"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="basis25">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href='https://www.figma.com/file/ba8hTYpbcXJRVcvfLCKSGP/Metrics-Intensive-Landing-Page-%5BLocked%5D?t=0pd8feXmiTEAUglR-1'
                                    target="_blank"
                                    aria-label="Screenshot of the landing page for Research Program"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={metricsFigma}
                                        alt="Advances Approach To UX Research"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="basis25">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href={goalsBig}
                                    target="_blank"
                                    aria-label="Screenshot of the landing page for Research Program"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={goalsSmall}
                                        alt="Advances Approach To UX Research"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w800" style={{marginTop:"80px"}}>
            <p>Check my <strong>Over-optimized (Webinar) Plugin for Plan Selling - OOPPS</strong>. I began by conducting research (<a href="https://oopps-cs.xdmr.us/" target="_blank" rel="noopener noreferrer"><strong>UX Case Study</strong></a>), then I created a <a href="https://www.figma.com/file/fkA6RMJr5JC5KiiNP5RCZ3/Over-optimized-Plugin-for-(Webinar)-Plan-Selling---OOPS?node-id=202%3A626&t=kUnxVR5L4qPdZ7LM-0" target="_blank" rel="noopener noreferrer">
            <strong>Figma</strong></a>, and finally, I developed a ⚛️ {(/ /g, "\u00A0")}<a href="https://oops.xdmr.us/" target="_blank" rel="noopener noreferrer"><strong>React{(/ /g, "\u00A0")}App</strong></a> using the React Context API and Vanilla{(/ /g, "\u00A0")}CSS. The app is integrated with the Stripe API to facilitate seamless checkout.</p>
            <div className="flex" style={{ maxWidth: "700px", margin: "0 auto" }}>
                    <div className="basis30">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href="https://www.figma.com/file/fkA6RMJr5JC5KiiNP5RCZ3/Over-optimized-Plugin-for-(Webinar)-Plan-Selling---OOPS?node-id=202%3A626&t=P0XcyVZXHNZ6T3My-0"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Figma file that shows OOPS interface"
                                >
                                    <img
                                        src={figmaOOPS}
                                        alt="Screenshot of Figma file that shows OOPS interface"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="basis30">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href="https://github.com/xdMR/overOptimizedPPS"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="github repo for OOPS project"
                                >
                                    <img
                                        src={gitOOPS}
                                        alt="Screenshot of OOPS project in Github"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="basis30">
                        <div className="preview-shadow">
                            <div className="uie-preview">
                                <a
                                    href="https://oops.xdmr.us"
                                    target="_blank"
                                    aria-label="deployed OOPS project"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={liveOOPS}
                                        alt="Screenshot of deployed OOPS project"
                                        className="img-resp "
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About