import React from 'react'
import '../style/project.css'

import thinkcherokee from "../img/thinkcherokee.png";
import thinkcherokeemobile from "../img/thinkcherokee-mobile.png";
import thinkcherokeemobilefull from "../img/thinkcherokee-mobile-full.png";

import switchTV1 from "../img/switchTV1.gif";
import switchTV1small from "../img/switchTV1-small.gif";
import switchTV2 from "../img/switchTV2.gif";
import switchTV2small from "../img/switchTV2-small.gif";

import isuzu from "../img/isuzu.gif";
import isuzuSmall from "../img/isuzu-small.gif";
import isuzuxd from "../img/isuzu-xd.png";
import isuzuOld from "../img/isuzuba.png";
import fiveplus from "../img/5plus.svg";

import spellTell01 from "../img/projects/spellTellPlay1.webp"
import spellTell02 from "../img/projects/spellTell02.png"





function Project() {
    return (
        <>
            <div class="project section-space">
                <div class="bg-dotted">
                </div>

                <div class="container box-t-container">
                    <div class="project-slur">
                        <h2>SpellTell</h2>
                    </div>
                    <div class="box-ts">
                        <div class="box-t">
                            <div>
                                <a href="https://github.com/SpellTell/spelltell_react_public"
                                    target="_blank"
                                    aria-label="Github Repo of SpellTell App"
                                    rel="noopener noreferrer">Github
                                    <span>↗</span></a>
                            </div>

                            <div>
                                <a href="http://spelltell.com"
                                    target="_blank"
                                    aria-label="SpellTell Live Website"
                                    rel="noopener noreferrer">SpellTell
                                    <span>↗</span></a>
                            </div>


                        </div>
                        <div class="project-desc">
                            <p> SpellTell is an educational app that helps adults improve their spelling. SpellTell is a quick and easy way to practice spelling whenever a user can find a spare minute. Exercises can be done while on mobile or desktop devices.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="display">

                        <a href="img/SpellTell-007.mp4"
                            aria-label="SpellTell Website Scheenshot"
                            target="_blank">
                            <img src={spellTell01} class="img-resp" alt="" />
                        </a>
                        <a href={spellTell02}
                            aria-label="SpellTell Website Scheenshot"
                            target="_blank">
                            <img src={spellTell02} class="img-resp  " alt="" />
                        </a>



                    </div>
                </div>
            </div>





            <div class="project section-space">
                <div class="bg-dotted">
                </div>

                <div class="container box-t-container">
                    <div class="project-slur">
                        <h2>Isuzu.ba</h2>
                    </div>
                    <div class="box-ts">
                        <div class="box-t">
                            <div>
                                <a href="https://xd.adobe.com/view/c03d5c8c-ecb4-4a52-a943-e5c9fdcc2c7f-956e/"
                                    target="_blank"
                                    aria-label="Adobe.XD file for Isuzu.ba "
                                    rel="noopener noreferrer">AdobeXD
                                    <span>↗</span></a>
                            </div>
                            <div>
                                <a href="https://github.com/xdMR/isuzu.ba"
                                    target="_blank"
                                    aria-label="Isuzu.ba Github Repo"
                                    rel="noopener noreferrer">GitHub
                                    <span>↗</span></a>
                            </div>
                            <div>
                                <a href="https://isuzu.ba"
                                    aria-label="Isuzu.ba Website"
                                    target="_blank"
                                    rel="noopener noreferrer">Live
                                    <span>↗</span></a>
                            </div>


                        </div>
                        <div class="project-desc">
                            <p>For the past <span class="badge"><img src={fiveplus} alt="" /></span> years I have been working with <a href="https://isuzu.ba"
                                aria-label="Isuzu.ba Website"
                                target="_blank"
                                rel="noopener noreferrer">Isuzu.ba</a>, the main ISUZU dealership in Bosnia and Herzegovina. I have designed and developed multiple websites for their business and currently, I'm responsible for maintaining their web properties, web & email servers. You can check some of my previous <a href={isuzuOld}
                                    target="_blank"
                                    rel="noopener noreferrer">old
                                    designs</a>. The current desing is active on the main domain. You can also take a peak at one of my most recent design suggestions (<a
                                        href="https://isuzu.ba/isuzu.xd"
                                        aria-label="Adobe.XD file for Isuzu.ba "
                                        target="_blank"
                                        rel="noopener noreferrer">AdobeXD</a>). </p>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="display">

                        <a href={isuzu}
                            aria-label="Isuzu.ba Website Scheenshot"
                            target="_blank">
                            <img src={isuzuSmall} class="img-resp" alt="" />
                        </a>
                        <a href={isuzuxd}
                            aria-label="Isuzu.ba Website Scheenshot"
                            target="_blank">
                            <img src={isuzuxd} class="img-resp  " alt="" />
                        </a>

                    </div>
                </div>
            </div>


            <div className="project section-space">
                <div className="bg-dotted">
                </div>

                <div className="container box-t-container">
                    <div className="project-slur">
                        <h2>thinkCherokee</h2>
                    </div>
                    <div className="box-ts">
                        <div className="box-t">
                            <div>
                                <a href="https://thinkcherokee.com"
                                    target="_blank"
                                    aria-label="Think Cherooke Website"
                                    rel="noopener noreferrer">Live
                                    <span>↗</span></a>
                            </div>
                            <div>
                                <a href="https://github.com/xdMR/thinkCherokee"
                                    target="_blank"
                                    aria-label="Think Cherooke Github Repo"
                                    rel="noopener noreferrer">GitHub
                                    <span>↗</span></a>
                            </div>
                            <div>
                                <a href="thinkcherokee.xd"
                                    target="_blank"
                                    aria-label="Think Cherooke XD File"
                                    rel="noopener noreferrer">AdobeXD <span>↗</span></a>
                            </div>

                        </div>
                        <div className="project-desc">
                            <p>I designed a website dedicated to the preservation of the Cherokee language and culture. My design thinking for this project is summarized in the essay: <a href="https://article.xdmr.us/saving_a_dying_language"
                                target="_blank"
                                aria-label="Article - Saving a Dying Language"
                                rel="noopener noreferrer">Saving a
                                Dying Language</a>.

                                {/* This experience help me create "Cherokee Red",new book medium themes, for  <a href="https://scholar.harvard.edu/nicholasbelmore">Mr. Belmore</a>'s book series published for <a href="https://www.seeinghope.com/">Seeing Hope</a> organization. */}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="display">

                        <a href={thinkcherokee}
                            aria-label="Think Cherooke Website Scheenshot"
                            target="_blank">
                            <img src={thinkcherokee} className="img-resp" alt="" />
                        </a>
                        <a href={thinkcherokeemobilefull}
                            aria-label="Think Cherooke Mobile Scheenshot"
                            target="_blank">
                            <img src={thinkcherokeemobile} className="img-resp  " alt="" />
                        </a>

                    </div>
                </div>
            </div>

        </>



    )
}

export default Project